import * as React from 'react';
import { graphql } from 'gatsby';
import _ from 'lodash';
import styled from 'styled-components';
import Gallery from '@browniebroke/gatsby-image-gallery';
import '@browniebroke/gatsby-image-gallery/dist/style.css';

import { PAGES } from '../config/constants';
import { withQuery } from '../utils/withQuery';
import Layout from '../components/layout';
import Heading from '../components/heading';
import ExtraContent from '../components/extra-content';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';

const GalleryContainer = styled.div`
  padding: .25rem .5rem;
  margin-bottom: 4.5rem;

  img {
    cursor: pointer;
  }
  
  > div {
    margin: 0 -1rem;
    
    > div {
      width: 50% !important;
      flex-basis: 50%;
      max-width: 50%;

      @media screen and (min-width: 481px) {
        width: 33.33% !important;
        flex-basis: 33.33% ;
        max-width: 33.33% ;
      }
      
      @media screen and (min-width: 769px) {
        width: 25% !important;
        flex-basis: 25%;
        max-width: 25%;
      }
      
      > div {
        margin: .25rem;
        border: 2px solid #212121;
        
        @media screen and (min-width: 481px) {
          margin: .5rem;
        }
      }
    }
  }
`;

const Artwork = ({ galleries, extraContent: { title, content } }) => (
  <Layout
    pageTitle="Artwork"
    pageDescription="A gallery of illustration, painting, collage and print work by Linda Black"
    breadcrumbTrail={[PAGES.ARTWORK]}
  >
    <Heading>Artwork</Heading>
    {galleries.map(({ title, images }) => (
      <div key={title}>
        <h2>{title}</h2>
        <GalleryContainer>
          <Gallery images={images}/>
        </GalleryContainer>
      </div>
    ))}
    <ExtraContent>
      <h2>{title}</h2>
      {content}
    </ExtraContent>
  </Layout>
);

// https://www.gatsbyjs.com/plugins/gatsby-image/#gatsby-source-contentful
export const query = graphql`
  query Artworks {
    allContentfulArtwork(sort: {fields: [from___publishDate, title], order: [DESC, ASC]}) {
      group(field: type) {
        fieldValue
        totalCount
        edges {
          node {
            title
            type
            medium
            date
            image {
              id
              thumb: fluid(maxWidth: 328, maxHeight: 328, quality: 100) {
                ...GatsbyContentfulFluid
              }
              full: fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
            from {
              id
              title
              publisher
              publishDate
            }
          }
        }
      }
    }
    contentfulPage(contentful_id: { eq: "40Kq0rKoQa8qgbePucFSDR" }) {
      title
      content {
        raw
      }
    }
  }
`;

const mapDataToProps = ({ allContentfulArtwork: { group }, contentfulPage: { title, content } }) => {
  // todo: filter before ordering by total
  //  or exclude those that don't have thumb && full from graph ql query, so totalCount is correct.
  const galleries = _.orderBy(group, 'totalCount', 'desc').map(({ fieldValue, edges }) => {
    return {
      title: fieldValue,
      images: edges.map(({ node: { title, medium, image, from, date } }) => {
        let caption = '';

        if (from) {
          caption = `from ${from.title}, ${new Date(from.publishDate).getFullYear()}`;
        } else if (date) {
          caption = `${new Date(date).getFullYear()}`;
        }

        if (medium) {
          caption = caption ? `${medium.toLowerCase()} (${caption})` : medium.toLowerCase();
        }

        return {
          ...image,
          title,
          caption,
        };
      }).filter(({ thumb, full }) => thumb && full),
    };
  });
  return {
    galleries,
    extraContent: {
      title,
      content: documentToReactComponents(
        JSON.parse(content.raw),
        {
          renderNode: {
            [INLINES.HYPERLINK]: (node, children) => (
              <a href={node.data.uri} target="_blank" rel="noreferrer noopener">
                {children}
              </a>
            ),
          },
        },
      ),
    },
  };
};

export default withQuery(mapDataToProps)(Artwork);
